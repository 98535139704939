<template>
  <b-card
    no-body
    class="mb-1"
  >
    <b-card-header class="py-1">
      <h4 class="card-title font-weight-bolder">
        {{ $t('filters') }}
      </h4>

      <!-- ANCHOR Button Add -->
      <b-button
        v-if="isManager"
        variant="info"
        class="px-75 py-50"
        @click="handleShowModalResell"
      >
        <span class="text-nowrap d-flex-center gap-1">
          <feather-icon
            icon="PlusIcon"
            size="16"
          />
          {{ $t('resellTicket.create') }}
        </span>
      </b-button>
    </b-card-header>

    <b-card-body class="pt-0 pb-50">
      <div class="d-flex-center gap-2">
        <b-row class="flex-1">
          <b-col
            cols="12"
            md="3"
          >
            <!-- ANCHOR AGENCY CODE -->
            <b-form-group
              label-class="h5 py-0"
              :label="$t('resellTicket.columns.paxName')"
            >
              <b-form-input
                v-model.trim="filter.searchText"
                :placeholder="$t('resellTicket.columns.paxName')"
                :formatter="onlyUpperCaseFormatter"
                debounce="500"
              />
            </b-form-group>
          </b-col>

          <!-- ANCHOR START DATE -->
          <!-- <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label-class="h5 py-0 mb-0"
              label-for="startDate"
              :label="$t('topup.startDate')"
            >
              <flat-pickr
                id="startDate"
                v-model="filter.fromDate"
                :name="$t('topup.startDate')"
                :config="{
                  ...configFlatPickr,
                  minDate: '',
                  maxDate: 'today',
                  altInputClass: 'form-control px-50 py-0 height-fit',
                }"
                :placeholder="$t('topup.placeholderSelectDate')"
              />
            </b-form-group>
          </b-col> -->

          <!-- ANCHOR END DATE -->
          <!-- <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label-class="h5 py-0 mb-0"
              label-for="toDate"
              :label="$t('topup.endDate')"
            >
              <flat-pickr
                id="toDate"
                v-model="filter.toDate"
                :name="$t('topup.endDate')"
                :config="{
                  ...configFlatPickr,
                  minDate: filter.fromDate,
                  maxDate: 'today',
                  altInputClass: 'form-control px-50 py-0 height-fit',
                }"
                :placeholder="$t('topup.placeholderSelectDate')"
              />
            </b-form-group>
          </b-col> -->
          <b-col
            v-if="isRoleF1"
            md="3"
          >
            <b-form-group
              label-for="type"
              :label="$t('resellTicket.btn.report')"
              label-class="h5 py-0 mb-0"
            >
              <v-select
                id="type"
                v-model="filter.reported"
                :placeholder="$t('resellTicket.reported')"
                :options="['reported','unReported']"
                :clearable="false"
                :reduce="(val)=>val === 'reported'"
              >
                <template #option="data">
                  <span>
                    {{ $t('resellTicket.' + data.label) }}
                  </span>
                </template>

                <template #selected-option="data">
                  <span>
                    {{ $t('resellTicket.' + data.label) }}
                  </span>
                </template>

                <template #no-options>
                  {{ $t('noOptions') }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            v-if="isManager"
            md="3"
          >
            <b-form-group
              label-for="type"
              :label="$t('resellTicket.columns.status')"
              label-class="h5 py-0 mb-0"
            >
              <v-select
                id="type"
                v-model="filter.status"
                :placeholder="$t('resellTicket.columns.status')"
                :options="status"
                :clearable="true"
              >
                <template #option="data">
                  <span>
                    {{ $t('resellTicket.status.' + data.label) }}
                  </span>
                </template>

                <template #selected-option="data">
                  <span>
                    {{ $t('resellTicket.status.' + data.label) }}
                  </span>
                </template>

                <template #no-options>
                  {{ $t('noOptions') }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="d-flex align-items-center justify-content-end">
          <!-- ANCHOR Button Clear Search Filters -->
          <b-button
            variant="flat-danger"
            class="p-50 rounded-circle"
            @click="onClearFilters"
          >
            <IAmIcon
              icon="revisionOutline"
              size="18"
            />
          </b-button>
        </div>
      </div>
      <b-row
        v-if="isManager"
        class="flex flex-column px-1 py-0"
      >
        <span class="text-info h4">{{ $t('resellTicket.note.title') }}</span>
        <span>- {{ $t('resellTicket.note.note1') }}</span>
        <span class="text-danger">- {{ $t('resellTicket.note.note2') }}</span>
        <span class="text-danger">- {{ $t('resellTicket.note.note3') }}</span>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BFormGroup,
  BFormInput,
  BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'

import VueI18n from '@/libs/i18n'
import store from '@/store'

import { onlyUpperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BFormGroup,
    BFormInput,
    vSelect,
    flatPickr: () => import('vue-flatpickr-component'),
  },
  props: {
    filter: {
      type: Object,
      default: null,
    },
    isManager: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const status = ref([
      'UNPUBLIC',
      'PUBLIC',
      'SOLD',
    ])
    const onClearFilters = () => {
      emit('fetch-clear')
    }

    function handleShowModalResell() {
      this.$bvModal.show('create-resell-ticket-modal')
    }

    const locale = computed(() => VueI18n.locale).value

    const configFlatPickr = ref(
      {
        dateFormat: 'Y-m-d',
        minDate: 'today',
        maxDate: '',
        locale: locale === 'vi' ? Vietnamese : null,
        allowInput: true,
        altInput: true,
        altFormat: 'd-m-Y',
        shorthandCurrentMonth: true,
        disableMobile: true,
      },
    )
    return {
      onClearFilters,
      handleShowModalResell,
      onlyUpperCaseFormatter,
      configFlatPickr,
      isRoleF1,
      status,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
